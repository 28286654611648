<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Evaluaciones Orion</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Documentación</li>
                  <li class="breadcrumb-item active">Evaluaciones Orion</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5" :class="form.id ? 'col-md-5' : 'col-md-12'">
                <div class="card-body">
                  <div class="card card-secondary card-outline col-md-12">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-scroll"></i>
                        Formulario {{ accion }} Evaluaciones Orion
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-md-4"
                          :class="form.id ? 'col-md-12' : 'col-md-6'"
                        >
                          <div class="form-group">
                            <label for="nombre">Nombre</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="form.nombre"
                              :class="
                                $v.form.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="form.estado == 2"
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-4"
                          :class="form.id ? 'col-md-6' : 'col-md-6'"
                        >
                          <div class="form-group">
                            <label for="linea_negocio">Linea De Negocio</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.linea_negocio_id"
                              :class="
                                $v.form.linea_negocio_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="form.estado == 2"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="linea_negocio in listasForms.lineas_negocio"
                                :key="linea_negocio.id"
                                :value="linea_negocio.id"
                              >
                                {{ linea_negocio.nombre }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                        v-if="form.id"
                          class="col-md-4"
                          :class="form.id ? 'col-md-6' : 'col-md-4'"
                        >
                          <div class="form-group">
                            <label for="estado">Estado</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.estado"
                              :class="
                                $v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @change="save()"
                              :disabled="form.estado == 2"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-md-4"
                          :class="form.id ? 'col-md-6' : 'col-md-4'"
                        >
                          <div class="form-group">
                            <label for="nombre">Intentos Permitidos</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="form.n_intentos"
                              :class="
                                $v.form.n_intentos.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="form.estado == 2"
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-4"
                          :class="form.id ? 'col-md-6' : 'col-md-4'"
                        >
                          <div class="form-group">
                            <label for="nombre">Porcentaje Aprobación</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="form.porcentaje"
                              :class="
                                $v.form.porcentaje.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="form.estado == 2"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="descripcion">Descripción</label>
                            <textarea
                              class="form-control form-control-sm"
                              id="obs"
                              cols="20"
                              rows="2"
                              v-model="form.descripcion"
                              :disabled="form.estado == 2"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <button
                          :class="form.id ? 'col-lg-3' : 'col-lg-1'"
                          class="btn bg-primary"
                          v-show="!$v.form.$invalid"
                          @click="save()"
                        >
                          <i class="fas fa-paper-plane"></i><br />Guardar
                        </button>
                        <button
                          :class="form.id ? 'col-lg-3' : 'col-lg-1'"
                          class="btn bg-secondary ml-1"
                          @click="back()"
                        >
                          <i class="fas fa-reply"></i><br />Volver
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card-body">
                  <div
                    class="card card-secondary card-outline col-md-12"
                    v-show="form.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-book-reader"></i>
                        Agregar Material Evaluación
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <label>Descripción</label>
                          <input
                            type="text"
                            placeholder="Descripcion"
                            id="descripcion"
                            v-model="formMateriales.descripcion"
                            class="form-control form-control-sm"
                            :class="
                              $v.formMateriales.descripcion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="estado">Tipo</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="formMateriales.tipo"
                            :class="
                              $v.formMateriales.tipo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo in listasForms.tipos_material"
                              :key="tipo.numeracion"
                              :value="tipo.numeracion"
                            >
                              {{ tipo.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Adjunto</label>
                          <input
                            type="file"
                            id="archivo-adjunto-materiales"
                            class="form-control-file pt-1"
                            accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                            @change="obtenerArchivo"
                            :class="
                              $v.formMateriales.link.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-1 pt-4">
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            @click="saveMaterial()"
                            v-show="!this.$v.formMateriales.$invalid"
                          >
                            <i class="fas fa-check-circle"></i>
                          </button>
                        </div>
                        <div class="card-body p-0">
                          <table
                            id="sitios"
                            class="table table-bordered table-striped table-hover table-sm"
                          >
                            <thead>
                              <tr>
                                <th>Descripción</th>
                                <th>Tipo</th>
                                <th>Adjunto</th>
                                <th>Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="mat in materiales" :key="mat.id">
                                <td v-text="mat.descripcion"></td>
                                <td class="text-center">
                                  <span
                                    class="badge"
                                    v-bind:class="[
                                      mat.tipo == 1
                                        ? 'badge-danger'
                                        : mat.tipo == 2
                                        ? 'badge-info'
                                        : 'badge-dark',
                                    ]"
                                  >
                                    {{ mat.nTipo }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <a
                                    :href="uri_docs + mat.link"
                                    target="_blank"
                                    :class="mat.tipo != 3 ? 'btn bg-dark' : ''"
                                  >
                                    <img
                                      :src="uri_docs + mat.link"
                                      width="75"
                                      class="rounded mx-auto d-block"
                                      v-if="mat.tipo == 3"
                                    />
                                    <i
                                      :class="
                                        mat.tipo == 1
                                          ? 'fas fa-file-pdf bg-danger'
                                          : mat.tipo == 2
                                          ? 'far fa-file-video'
                                          : ''
                                      "
                                    ></i>
                                  </a>
                                </td>
                                <td class="text-center">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="destroyMaterial(mat.id)"
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card-body">
                  <div
                    class="card card-secondary card-outline col-md-12"
                    v-show="form.id"
                  >
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-10">
                          <h3 class="card-title">
                            <i class="fas fa-question"></i>
                            Agregar Preguntas
                          </h3>
                        </div>
                        <div class="col-md-2">
                          <div class="btn float-right">
                            <VueFeather
                              style="cursor: pointer; color: darkblue"
                              @click="addQuestion()"
                              type="plus-circle"
                            ></VueFeather>
                            <VueFeather
                              style="cursor: pointer; color: darkred"
                              @click="removeLastQuestion()"
                              type="minus-circle"
                            ></VueFeather>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="card card-info card-outline col-md-12"
                          v-for="(item, indice) in groups[0].items"
                          :key="item.id"
                          :data-id="item.id"
                        >
                          <!-- Contenido del div aquí -->

                          <div class="card-header">
                            <div class="row">
                              <div class="col-md-11">
                                <h3 class="card-title">
                                  <i class="fas fa-check"></i>
                                  <b> Pregunta {{ indice + 1 }} </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="col-md-12">
                              <label for="pregunta">Descripción</label>
                              <textarea
                                v-model="groups[0].items[indice].descripcion"
                                style="resize: none"
                                rows="2"
                                class="form-control form-control-sm"
                              ></textarea>
                            </div>
                            <div class="col-md-12 mt-4">
                              <div class="row">
                                <div class="col-md-2 mb-3">
                                  <b>Seleccione la correcta</b>
                                </div>
                                <div class="col-md-3">
                                  <b>Posible Respuesta</b>
                                </div>
                                <div class="col-md-7">
                                  <div class="btn float-right">
                                    <VueFeather
                                      style="cursor: pointer; color: darkblue"
                                      @click="addResp(indice)"
                                      type="plus-circle"
                                    ></VueFeather>
                                    <VueFeather
                                      style="cursor: pointer; color: darkred"
                                      @click="removeLastResp(indice)"
                                      type="minus-circle"
                                    ></VueFeather>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="row"
                                v-for="(resp, indice1) in groups[0].items[
                                  indice
                                ].respuestas"
                                :key="resp.id"
                                :data-id="resp.id"
                              >
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <input
                                      type="checkbox"
                                      v-model="resp.estado"
                                      @change="
                                        handleCheckboxChange(indice, indice1)
                                      "
                                    />
                                  </div>
                                </div>
                                <div class="col-md-10">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="resp.descripcion"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="btn float-right p-0 m-0">
                            <button
                              type="button"
                              class="btn btn-info"
                              @click="savePreg()"
                            >
                              <i class="fas fa-check"> Enviar</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, numeric, maxLength } from "vuelidate/lib/validators";
import VueFeather from "vue-feather";
import Loading from "../../../../components/Loading";
/* import vSelect from "vue-select"; */

export default {
  name: "EvaluacionesForm",
  components: {
    VueFeather,
    Loading,
    /* vSelect, */
  },
  data() {
    return {
      cargando: true,
      documento: null,
      materiales: null,
      archivoValido: true,
      formMateriales: {
        descripcion: null,
        tipo: null,
        link: null,
      },
      groups: [
        {
          id: 1,
          evaluacion_id: null,
          items: [],
        },
      ],
      form: {
        id: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        estados: [],
        lineas_negocio: [],
        tipos_material: [],
      },
      documentosHasPaquetes: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      descripcion: {
        required,
      },
      estado: {
        required,
      },
      linea_negocio_id: {
        required,
      },
      n_intentos: {
        required,
        numeric,
        maxLength: maxLength(2),
      },
      porcentaje: {
        required,
        numeric,
        maxLength: maxLength(3),
      },
    },
    formMateriales: {
      descripcion: {
        required,
      },
      tipo: {
        required,
      },
      link: {
        required,
      },
    },
  },
  computed: {
    areAllGroupsValid() {
      return this.groups[0].items.every((item) => this.isGroupValid(item));
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        if (this.form.materiales.length > 0) {
          this.materiales = this.form.materiales;
        }

        if (this.form.preguntas.length > 0) {
          this.form.preguntas.forEach(async (pregs, indiPreg) => {
            this.groups[0].items.push({
              id: pregs.id,
              descripcion: pregs.descripcion,
              tipo: pregs.descripcion,
              estado: pregs.estado,
              respuestas: pregs.respuestas,
            });

            let validacion = await this.isGroupValid(pregs);
            this.groups[0].items[indiPreg].val = validacion;
          });
        }

        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
      }
      this.cargando = false;
    },

    isGroupValid(item) {
      //this.groups[0].items[indiPreg].val = validacion;
      return (
        item.respuestas.every((resp) => resp.descripcion) &&
        item.respuestas.some((resp) => resp.estado === 1) &&
        item.descripcion != null &&
        item.descripcion != ""
      );
    },

    isPregValid(group) {
      return (
        group.respuestas.every((resp) => resp.descripcion) &&
        group.respuestas.some((resp) => resp.estado === 1) &&
        group.descripcion != null &&
        group.descripcion != ""
      );
    },

    handleCheckboxChange(groupIndex, respIndex) {
      this.groups[0].items[groupIndex].respuestas.forEach((resp, index) => {
        if (index !== respIndex) {
          resp.estado = false; // Desmarca todas las respuestas, excepto la seleccionada
        }
      });
    },

    savePreg() {
      this.cargando = true;
      this.groups[0].evaluacion_id = this.form.id;
      axios
        .post("/api/admin/evaluaciones/savePreguntasResp", this.groups[0])
        .then((response) => {
          //this.init();
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })        
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposMaterial() {
      axios.get("/api/lista/179").then((response) => {
        this.listasForms.tipos_material = response.data;
      });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    addResp(indice) {
      if (this.groups[0].items[indice].length > 0) {
        this.groups[0].items[indice].respuestas.push({
          id: null,
          estado: 0,
          descripcion: null,
        });
      } else {
        this.groups[0].items[indice].respuestas.push({
          id: null,
          estado: 0,
          descripcion: null,
        });
      }
    },

    addQuestion() {
      if (this.groups[0].items.length > 0) {
        this.groups[0].items.push({
          id: null,
          tipo: 1,
          respuestas: [],
          estado: 1,
          val: false,
        });
      } else {
        this.groups[0].items.push({
          id: null,
          tipo: 1,
          respuestas: [],
          estado: 1,
          val: false,
        });
      }
    },

    removeLastQuestion() {
      if (this.groups[0].items.length > 0) {
        this.groups[0].items.pop();
      }
    },

    removeLastResp(indice) {
      if (this.groups[0].items[indice].respuestas.length > 0) {
        this.groups[0].items[indice].respuestas.pop();
      }
    },

    async saveMaterial() {
      let formData = new FormData();
      formData.append("evaluacion_id", this.form.id);
      formData.append("descripcion", this.formMateriales.descripcion);
      formData.append("tipo", this.formMateriales.tipo);
      formData.append("file", this.formMateriales.link);
      formData.append("accion", true);
      if (!this.$v.formMateriales.$invalid) {
        await axios
          .post("/api/admin/evaluaciones/saveMaterial", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.formMateriales.descripcion = null;
            this.formMateriales.tipo = null;
            this.formMateriales.link = null;
            this.archivoValido = false;
            document.getElementById("archivo-adjunto-materiales").value = "";
            this.materiales = response.data.materiales;

            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyMaterial(idMaterial) {
      this.formMateriales.accion = false;
      this.$swal({
        title: "Esta seguro de eliminar este documento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "POST",
            url: "/api/admin/evaluaciones/saveMaterial",
            data: {
              id: idMaterial,
              evaluacion_id: this.form.id,
              accion: false,
            },
          }).then((response) => {
            this.formMateriales.descripcion = null;
            this.formMateriales.tipo = null;
            this.formMateriales.link = null;
            this.materiales = response.data.materiales;
            this.$swal({
              icon: "success",
              title: "Se eliminó el documento exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    obtenerArchivo(e) {
      this.formMateriales.link = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.formMateriales.link = file;
        }
      } else {
        this.archivoValido = true;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1000000) {
        flag = false;
        this.formMateriales.link = null;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/evaluaciones",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;            
            this.$route.params.accion = "Editar";            
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            //this.init();
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/Evaluaciones");
    },
  },
  mounted() {
    this.init();
    this.getLineasNegocio();
    this.getEstados();
    this.getTiposMaterial();
  },
};
</script>
